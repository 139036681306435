import React from 'react'
import { graphql } from 'gatsby'
import Footer from '../components/footer'
import Seo from '../components/seo'
import { getImage } from "gatsby-plugin-image"
import Nav from '../components/nav'
import GoogleReviews from '../components/google_reviews'

const ContactPage = ({data}) => {
  const entry = data.markdownRemark
  const image = getImage(entry.frontmatter.image)
	return (
    <div>
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <Nav />
      <div className="w-full py-12 bg-blue">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex justify-around items-center text-center">
            <div className="w-8/12 lg:w-6/12 font-verdana">
              <h1 className="text-white font-bold text-3xl lg:text-5xl leading-tight">{entry.frontmatter.heading}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-12 pb-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex justify-between gap-24">
            <div className="w-full sm:w-8/12">
              <div className="max-w-none font-verdana prose">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
              <iframe src="https://tradehq.com/aucklandroofpainting/Enquire" style={{ fontfamily: 'Verdana', width:'100%', height:'1050px', border:'0', margin:'0', padding:'0', overflow:'hidden', zIndex:'999999'}}></iframe>
            </div>
            <div className="w-full sm:w-4/12 pt-12 sm:pt-0">
              <iframe width="100%" height="100%" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d102133.27447584386!2d174.74061493754704!3d-36.874440801810856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d48fb2cfce199%3A0x8c744dcc6d38c76f!2sAuckland%20Roof%20Painting!5e0!3m2!1sen!2snz!4v1645999154427!5m2!1sen!2snz" allowFullScreen={false} loading="lazy"></iframe>
            </div>
          </div>
        </div>
      </div>
      <GoogleReviews />
      <Footer />
    </div>
	)
}

export default ContactPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
        quote
        author
			}
			fields {
				slug
			}
      html
		}
	}
`